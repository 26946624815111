import React from 'react';

import Image from '@jetshop/ui/Image/Image';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import MaxWidth from '../../Layout/MaxWidth';
import { theme } from '../../Theme';
import { ButtonLink } from '../../ui/Button';

const StartPageHeroWrapper = styled(MaxWidth)`
   height: 750px;
   max-width: 100vw;
   padding: 0;
   ${theme.below.xl} {
      padding: 0px;
   }
   ${theme.below.md} {
      padding: 0px;
      height: 640px;
   }
`;
const Container = styled('div')`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: white;
   height: 100%;
   text-align: center;
`;
const Title = styled('h1')`
   font-weight: normal;
   margin-bottom: 20px;
   ${theme.below.md} {
      margin-bottom: 10px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
`;

const Description = styled('p')`
   width: 524px;
   font-size: var(--h4);
   font-family: var(--text-font);
   max-width: 100%;
   margin-bottom: 20px;
   line-height: normal;
   font-weight: 700;
   ${theme.below.md} {
      width: 100%;
      margin-bottom: 30px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
   }
`;

const StyledButton = styled(ButtonLink)`
   ${theme.below.sm} {
      font-size: 14px;
   }
`;

const heroStyles = css`
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;

   &:after {
      content: '';
      background-color: black;
      opacity: 0.1;
      mix-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
   }
`;

const StartPageHero = ({ header, text, buttonText, buttonLink, imageSrc }) => {
   return (
      <StartPageHeroWrapper>
         <Image
            fillAvailableSpace={true}
            src={imageSrc.value}
            className={heroStyles}
         >
            <Container>
               <Title>{header.value}</Title>
               <Description>{text.value}</Description>
               {buttonText.value && buttonLink.value && (
                  <StyledButton to={buttonLink.value}>
                     {buttonText.value}
                  </StyledButton>
               )}
            </Container>
         </Image>
      </StartPageHeroWrapper>
   );
};

export default StartPageHero;
