import React from 'react';

import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';

import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';

const Wrapper = styled(MaxWidth)`
   display: flex;
   align-items: center;
   flex-direction: column;

   margin-bottom: 100px;
   > h3 {
      text-align: center;
      width: 100%;
      padding-top: 86px;
      padding-bottom: 36px;
   }

   ${theme.below.lg} {
      align-items: start;
   }
`;

const LinkCardsWrapper = styled('div')`
   --shadowPaddingX: 50px;
   --shadowPaddingY: 50px;

   width: 100%;
   display: flex;
   justify-content: start;
   flex-direction: row;
   justify-content: start;
   gap: 20px;

   width: calc(100% + var(--shadowPaddingX) * 2);
   margin-left: calc(var(--shadowPaddingX) * -1);
   margin-right: calc(var(--shadowPaddingX) * -1);
   margin-top: calc(var(--shadowPaddingY) * -1);
   margin-bottom: calc(var(--shadowPaddingY) * -1);
   padding-left: var(--shadowPaddingX);
   padding-right: var(--shadowPaddingX);
   padding-top: var(--shadowPaddingY);
   padding-bottom: var(--shadowPaddingY);
   overflow-x: auto;

   ${theme.below.xl} {
      --shadowPaddingX: 2rem;
   }

   ${theme.below.sm} {
      --shadowPaddingX: 0.75rem;
   }

   ${theme.above.lg} {
      --shadowPaddingX: 0;
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
   }
`;

const Fade = styled('div')`
   width: 100%;
   height: 50%;
   position: absolute;
   bottom: 0;
   left: 0;
   background: linear-gradient(
      180deg,
      rgba(190, 183, 183, 0) 10.14%,
      rgba(194, 194, 194, 0.84) 100%
   );
   mix-blend-mode: multiply;
   opacity: 1;
`;

const CardWrapper = styled('a')`
   position: relative;
   width: 100%;
   filter: drop-shadow(var(--skugga-bakom-kort));
   min-width: 220px;

   ${theme.above.lg} {
      min-width: auto;
   }

   .card-image {
      padding-top: calc((333 / 278) * 100%);
   }

   &:hover {
      > h3 {
         opacity: 0.8;
         transition: all, 0.1s ease-in;
      }
   }

   > h3 {
      transition: all, 0.2s ease-in;
      position: absolute;
      color: var(--vit);
      bottom: 18px;
      transform: translateX(-50%);
      left: 50%;
      width: 100%;
      text-align: center;
      padding: 0 20px;
   }
`;

export const LinkCard = ({ title, image, link }) => {
   return (
      <CardWrapper href={link?.value}>
         {image?.value && (
            <Image
               className="card-image"
               fillAvailableSpace={true}
               cover={true}
               src={image.value}
            />
         )}
         <Fade />
         {title?.value && <h3>{title.value}</h3>}
      </CardWrapper>
   );
};

const LinkCards = ({ title, children }) => {
   return (
      <Wrapper>
         {title.value && <h3>{title.value}</h3>}
         <LinkCardsWrapper>{children}</LinkCardsWrapper>
      </Wrapper>
   );
};

export default LinkCards;
