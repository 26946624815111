import React from 'react';

import { styled } from 'linaria/react';

import Image from '@jetshop/ui/Image';

import { theme } from '../../Theme';
import { ButtonLink } from '../../ui/Button';

const Block = styled('div')`
	display: grid;
	position: relative;
	z-index: 0;
	grid-template-rows: auto;
	grid-template-columns: 100%;
	place-items: center;
	color: ${theme.colors.white};
`;

const Wrapper = styled('div')`
	grid-area: 1/1/1/1;
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 100%;
	position: relative;
	z-index: -1;
	width: 100%;
	height: 100%;

	&::before {
		content: '';
		display: block;
		padding-top: calc(100% * var(--block-ratio));
		grid-area: 1/1/1/1;
	}

	&::after {
		display: block;
		content: '';
		grid-area: 1/1/1/1;
		background: rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	* {
		grid-area: 1/1/1/1;
	}
`;

const Content = styled('div')`
	grid-area: 1/1/1/1;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5em;
	padding: 10%;
	text-align: center;
	position: relative;
	z-index: 2;

	h2 {
		margin: 0;
		line-height: 1.1;
	}

	a {
		margin-top: 3px;
	}
`;

export const ImageBlock = ({ image, title, subtitle, link, linkText }) => {
	return (
		<Block>
			<Wrapper>
				{image?.value && (
					<Image
						fillAvailableSpace={true}
						cover={true}
						src={image.value}
					/>
				)}
			</Wrapper>
			<Content>
				{title?.value && <h2>{title?.value}</h2>}
				{subtitle?.value && <h4>{subtitle?.value}</h4>}
				{link?.value && (
					<ButtonLink class="small" to={link?.value}>
						{linkText?.value}
					</ButtonLink>
				)}
			</Content>
		</Block>
	);
};

export default ImageBlock;
