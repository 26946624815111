import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const Grid = styled('div')`
	display: grid;
	grid-auto-flow: row;
	overflow: hidden;
	gap: 1px;
	width: 1600px;
	max-width: 100%;
	margin: 0 auto;
`;

const Row = styled('div')`
	display: grid;
	grid-template-columns: repeat(${props => props.columnCount}, 1fr);
	gap: 1px;

	--block-ratio: 1;

	${theme.below.md} {
		grid-template-columns: 1fr;
		--block-ratio: ${props => (props.columnCount === 2 ? 1.5 : 1)};
	}
`;
const allocateRows = (items, columnAllocation) => {
	let splicedItems = [...items];
	let rows = [];
	let rowSize = parseInt(columnAllocation) === 3 ? 3 : 2;

	while (splicedItems.length) {
		let row = splicedItems.splice(0, rowSize);
		//Spread row with array of empty elements to fit row size
		row = [...row, ...new Array(rowSize - row.length)];
		rows.push(row);

		if (columnAllocation === 'alternate') {
			rowSize = rowSize === 2 ? 3 : 2;
		}
	}

	return rows;
};

const ImageBlockGrid = ({ children, columnAllocation }) => {
	const rows = allocateRows(children, columnAllocation.value);

	return (
		<Grid>
			{rows.map((row, rowIndex) => (
				<Row key={rowIndex} columnCount={row.length}>
					{row}
				</Row>
			))}
		</Grid>
	);
};

export default ImageBlockGrid;
