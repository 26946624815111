import React from 'react';
import MaxWidth from '../../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as Caret } from '@jetshop/ui/svg/Carrot.svg';
import { Above } from '@jetshop/ui/Breakpoints';
import { useAnimation, swipeEffect } from '@jetshop/ui/hooks';

const Container = styled('div')`
  position: relative;
  padding-top: 48px;
  padding-bottom: 62px;
  ${theme.below.sm} {
    padding-top: 32px;
    padding-bottom: 38px;
  }
`;

export const Title = styled('h2')`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.75rem;
  ${theme.below.md} {
    font-size: 18px;
  }
`;

const ItemsContainer = styled('div')`
  display: flex;
  overflow-x: auto;

  margin-right: -12px;
  margin-left: -12px;

  ${theme.above.xl} {
    justify-content: center;
  }
`;

const Button = styled('button')`
  position: absolute;
  font-family: initial;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }

  &.left svg {
    transform: rotateZ(90deg);
  }

  &.right {
    right: 0;

    svg {
      transform: rotateZ(-90deg);
    }
  }
`;

const Row = ({ header, children }) => {
  return (
    <MaxWidth>
      <Container>
        {header && <Title>{header.value}</Title>}
        <ItemsContainer>{children}</ItemsContainer>
      </Container>
    </MaxWidth>
  );
};

export default Row;
