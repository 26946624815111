import React, { useEffect } from 'react';

import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';

import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theme';
import { ProductCard } from './ProductCard';

export const priceStyle = css`
   [data-flight-price] {
      display: flex;
   }

   .new-price {
      color: var(--red);
      margin-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
   }

   .old-price {
      color: ${theme.colors.darkerGrey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
   }
`;

export const Wrapper = styled('ul')`
   display: grid;

   ${theme.above.md} {
      grid-template-columns: repeat(3, 1fr);
   }
   .product-card {
   }
`;

export function ProductGrid({
   products,
   listName,
   loading,
   className,
   ...rest
}) {
   const track = useTracker();
   useEffect(() => {
      // Dont track anything if there are no products to render
      if (!products || products.length === 0) return;

      // Otherwise track a list view event
      track(trackListEvent({ listName, products }));
   }, [listName, products, track]);

   if (!products) return null;

   return (
      <Wrapper
         data-testid="product-grid"
         className={cx('product-grid', className, priceStyle)}
      >
         {products.map((product, index) => {
            return (
               <ProductCard
                  key={index + ':' + product.articleNumber}
                  product={product}
                  style={{ opacity: loading ? 0.5 : 1 }}
                  list={listName}
                  {...rest}
               >
                  <Favourite
                     product={product}
                     style={{
                        position: 'absolute',
                        padding: '0.5em',
                        fontSize: '1.5em',
                        top: 0,
                        right: '0.5em',
                     }}
                  />
               </ProductCard>
            );
         })}
      </Wrapper>
   );
}

export default ProductGrid;
